<template>
    <div>
        <CDataTable
                :items="summaryDetail"
                :fields="fields"
                :items-per-page="10"
                :active-page="1"
                :loading="loading"
                hover
                class="modalTable"
              >
                <template #b1="{item,index}">
                    <td v-if="item.b1" v-c-tooltip="toolTip(item.b1)"
                        :style="{'background-color':  item.b1.gender == 'E' ? '#20A8D8' : item.b1.gender == 'K'  ? 'plum' : 'transparent'}">
                        {{item.b1.val}}
                    </td>
                    <td v-else></td>
                </template>
                <template #b2="{item,index}">
                    <td v-if="item.b2" v-c-tooltip="toolTip(item.b2)"
                        :style="{'background-color':  item.b2.gender == 'E' ? '#20A8D8' : item.b2.gender == 'K'  ? 'plum' : 'transparent'}">
                        {{item.b2.val}}
                    </td>
                    <td v-else></td>
                </template>
                <template #b3="{item,index}">
                    <td v-if="item.b3" v-c-tooltip="toolTip(item.b3)"
                        :style="{'background-color':  item.b3.gender == 'E' ? '#20A8D8' : item.b3.gender == 'K'  ? 'plum' : 'transparent'}">
                        {{item.b3.val}}
                    </td>
                    <td v-else></td>
                </template>
                <template #b4="{item,index}">
                    <td v-if="item.b4" v-c-tooltip="toolTip(item.b4)"
                        :style="{'background-color':  item.b4.gender == 'E' ? '#20A8D8' : item.b4.gender == 'K'  ? 'plum' : 'transparent'}">
                        {{item.b4.val}}
                    </td>
                    <td v-else></td>
                </template>
                <template #b5="{item,index}">
                    <td v-if="item.b5" v-c-tooltip="toolTip(item.b5)"
                        :style="{'background-color':  item.b5.gender == 'E' ? '#20A8D8' : item.b5.gender == 'K'  ? 'plum' : 'transparent'}">
                        {{item.b5.val}}
                    </td>
                    <td v-else></td>
                </template>
                <template #b6="{item,index}">
                    <td v-if="item.b6" v-c-tooltip="toolTip(item.b6)"
                        :style="{'background-color':  item.b6.gender == 'E' ? '#20A8D8' : item.b6.gender == 'K'  ? 'plum' : 'transparent'}">
                        {{item.b6.val}}
                    </td>
                    <td v-else></td>
                </template>
                <template #b7="{item,index}">
                    <td v-if="item.b7" v-c-tooltip="toolTip(item.b7)"
                        :style="{'background-color':  item.b7.gender == 'E' ? '#20A8D8' : item.b7.gender == 'K'  ? 'plum' : 'transparent'}">
                        {{item.b7.val}}
                    </td>
                    <td v-else></td>
                </template>
                <template #b8="{item,index}">
                    <td v-if="item.b8" v-c-tooltip="toolTip(item.b8)"
                        :style="{'background-color':  item.b8.gender == 'E' ? '#20A8D8' : item.b8.gender == 'K'  ? 'plum' : 'transparent'}">
                        {{item.b8.val}}
                    </td>
                    <td v-else></td>
                </template>
                <template #b9="{item,index}">
                    <td v-if="item.b9" v-c-tooltip="toolTip(item.b9)"
                        :style="{'background-color':  item.b9.gender == 'E' ? '#20A8D8' : item.b9.gender == 'K'  ? 'plum' : 'transparent'}">
                        {{item.b9.val}}
                    </td>
                    <td v-else></td>
                </template>
              </CDataTable>
              <div style="display: flex; justify-content: space-between; align-items: center;">
                <CPagination
                    v-show="count > 10"
                    :pages="count / 10"
                    :active-page.sync="activePage"
                />
                <CButton color="primary" size="sm" @click="excelExport"> Excel Aktar</CButton>
              </div>
              
    </div>
</template>

<script>
   import moment from 'moment'
    export default{
        name: "InventorySummaryList",
        props:{
            params: Object,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            activePage: async function(val){
                let params = {
                    "page": val,
                    "start": (val-1)*10,
                    "limit": 10,
                    "pos": this.params.id
                };
                await this.$store.dispatch('inventory_summaryDetail', params)
            }
           
        },
        computed:{
            loading(){
                return this.$store.getters.inventoryLoading
            },
            summaryDetail: function(){
                let data = [];
                this.$store.getters.inventorySummaryDetail.map((sd, i) => {
                    let arr = sd.detail.split(",");
                    arr.map((a,index) =>{
                        if(a){
                            var det = a.split(':');
                            sd['b'+(index+1)]={val: det[1], gender: det[0].split('-')[2], tooltip: det[0]}
                            
                        }else{
                            sd['b'+(index+1)] = {val:'', gender: '', tooltip: ''};
                        }
                    })
                    
                    data.push(sd);
                })
                return data
            },
            count: function(){
                return this.$store.getters.inventorySummaryDetailCount
            }
        },
        data: function(){
            return{
                fields: [
                    {key: 'id', label: 'Id'},
                    {key: 'name', label: 'Ürün'},
                    {key: 'type', label: 'Türü'},
                    {key: 'amount', label: 'Toplam'},
                    {key: 'b1', label: 'B1'},
                    {key: 'b2', label: 'B2'},
                    {key: 'b3', label: 'B3'},
                    {key: 'b4', label: 'B4'},
                    {key: 'b5', label: 'B5'},
                    {key: 'b6', label: 'B6'},
                    {key: 'b7', label: 'B7'},
                    {key: 'b8', label: 'B8'},
                    {key: 'b9', label: 'B9'},
                ],
                page: 1,
                activePage: 1
            }
        },
        methods:{
            toolTip: function(data){
                return {content: data.tooltip};
            },
            async excelExport() {
                let params = { responseType: 'blob'}
                await this.$store.dispatch('inventorySummary_excel', params)
                if(this.$store.getters.inventoryStatus){
                    const url = window.URL.createObjectURL(new Blob([this.$store.getters.inventoryStatus]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "StokOzetiRapor_" + moment(new Date()).format('DD/MM/YYYY') + ".xls");
                    document.body.appendChild(link);
                    link.click();
                }
            },
        }
    }
</script>
<style scoped>
     .table-responsive{
        height: auto;
    }
</style>